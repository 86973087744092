.more-less-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    color: #515151;
    font-weight: bold;
    font-size: 16px;
}

.more-less-button:hover {
    color: #dd1541;
    cursor: pointer;
}
