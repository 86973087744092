.make-margins( @size: 64, @decrement: 8) when (@size >= 0) {
  .make-margins( @size - @decrement);

  @size-px: ~"@{size}px";

  .m-@{size} {
    margin: @size-px;
  }
  .mt-@{size} {
    margin-top: @size-px;
  }
  .mr-@{size} {
    margin-right: @size-px;
  }
  .mb-@{size} {
    margin-bottom: @size-px;
  }
  .ml-@{size} {
    margin-left: @size-px;
  }
  .mh-@{size} {
    margin-left: @size-px;
    margin-right: @size-px;
  }
  .mv-@{size} {
    margin-top: @size-px;
    margin-bottom: @size-px;
  }
}

.make-margins();

.make-paddings( @size: 128, @decrement: 8) when (@size >= 0) {
  .make-paddings( @size - @decrement);

  @size-px: ~"@{size}px";

  .p-@{size} {
    padding: @size-px;
  }
  .pt-@{size} {
    padding-top: @size-px;
  }
  .pr-@{size} {
    padding-right: @size-px;
  }
  .pb-@{size} {
    padding-bottom: @size-px;
  }
  .pl-@{size} {
    padding-left: @size-px;
  }
  .ph-@{size} {
    padding-left: @size-px;
    padding-right: @size-px;
  }
  .pv-@{size} {
    padding-top: @size-px;
    padding-bottom: @size-px;
  }
}

.make-paddings();
