.text-hint {
    color: #7A7A7A !important;
}
.icon-hint {
    color: #7A7A7A !important;
    font-size: 20px;
    margin-right: 18px;
}

.infobox-item-wrapper p {
    color: #DD1541;
    cursor: pointer;
}

.infobox-icon {
    color: #DD1541;
    font-size: 20px;
    margin-left: 9px;
    cursor: pointer;
}

.info-gr-bg {
    background-color: #F5F5F5;
    border-radius: 8px;
}
