.debug-console-wrapper {
    padding: 8px;
    background-color: #292929;
    color: #fff;
    border: 1px solid #ccc;
    max-height: 500px;
    overflow-y: scroll;
}

pre .string { color: #c6cc1f; }
pre .number { color: #259ce6; }
pre .boolean { color: #f21bd2; }
pre .null { color: #f27e11; }
pre .key { color: #1f871e; }
