.solution-checkbox-list {
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 24px;
}

.solution-list-item-wrapper {
    border-bottom: 2px solid #ccc;
    width: 100%;
}

.solution-collapse {
    background-color: transparent !important;
    border: none !important;
}

.ant-collapse-item.solution-collapse-panel > .ant-collapse-header {
    padding: 0 !important;
}

.ant-collapse-item.solution-collapse-panel > .ant-collapse-content {
    background-color: transparent;
}

.solution-collapse-panel > .ant-collapse-header > div > .ant-checkbox-wrapper > span:last-child {
    width: 100%;
}

.solution-chart-icon {
    flex-grow: 1;
}

.solution-list-item-button-wrapper {
    padding-right: 40px;
}

@media (max-width: 991px) {
    .solution-collapse-panel > .ant-collapse-header > div > .ant-checkbox-wrapper {
        padding: 12px 0 !important;
    }
}

label.ant-checkbox-wrapper.bypass-solution {
    align-items: center;
}

label.ant-checkbox-wrapper.bypass-solution > span.ant-checkbox {
    padding-bottom: 7px;
}
