.recommendation-header {
    height: 64px;
    font-size: 16px;
    line-height: 24px;
    padding: 20px 16px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 6px;
}
.recommendation-header.contentVisible {
    border-radius: 6px 6px 0 0;
}

.recommendation-header .left-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.recommendation-header .left-content .debug-badge {
    margin-left: 8px;
    cursor: pointer;
}

.recommendation-header .material-icons {
    font-size: 28px !important;
    cursor: pointer;
}

.primary {
    background-color: #DD1541;
    color: white;
}

.secondary {
    background-color: #E0E0E0;
    color: #292929;
}

.recommendation-content {
    padding: 16px;
    display: flex;
    gap: 16px;
    background-color: #F5F5F5;
    flex-wrap: wrap;
    border-radius: 0 0 6px 6px;
}
