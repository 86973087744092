.legal-notice {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
}

.legal-notice:hover {
    text-decoration: underline;
}
