@import './colors';

.ant-steps-item-wait {
  .ant-steps-item-container {
    .ant-steps-item-tail::after {
      background-color: @pv-grey-96 !important;
      width: 100% !important;
      margin: 0 !important;
    }

    .ant-steps-item-content > .ant-steps-item-title {
      color: @pv-grey-80 !important;
    }
  }

  .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: @pv-grey-96 !important;
  }
}

.ant-steps-item-process {
  .ant-steps-item-container {
    .ant-steps-item-tail::after {
      background-color: @pv-grey-96 !important;
      width: 100% !important;
      margin: 0 !important;
    }

    .ant-steps-item-content > .ant-steps-item-title {
      color: @pv-grey-16 !important;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: @success-color !important;
  }
}

.ant-steps-item-finish {
  .ant-steps-item-container {
    .ant-steps-item-tail::after {
      background-color: @success-color !important;
      width: 100% !important;
      margin: 0 !important;
    }

    .ant-steps-item-content > .ant-steps-item-title {
      color: @pv-grey-16 !important;
    }
  }

  .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: @success-color !important;
  }
}

.ant-steps-item > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon > .ant-steps-icon-dot {
  height: 150%;
  width: 150%;
  margin-left: -25%;
  margin-top: -25%;
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon > .ant-steps-icon-dot {
  height: 168%;
  width: 168%;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05);
  margin-left: -34%;
  margin-top: -34%;
}

