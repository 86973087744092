.saved-calculation-wrapper {
    background-color: #525252;
    color: #fff;
}

.saved-calculation-wrapper > div > h5 {
    color: #fff !important;
}

span.anticon.anticon-file-text {
    font-size: 50px;
}

button.ant-btn.save-calculation-button {
    background: #525252;
    border-color: #fff;
    color: #fff;
    width: 100%;
}

th, td {
    text-align: left;
    border-bottom: 1px solid #cccccc;
}
th {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
    color: #292929;
    font-weight: normal;
}
td {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;
}

.calculation-title {
    font-weight: bold;
}

.calculation-title:hover {
    cursor: pointer;
    color: #dd1541;
    text-decoration: underline;
}
