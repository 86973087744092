@import "~antd/dist/antd.less";
@import (less) "~@dreebit/pv-components/dist/index.css";
@import "./ant";
@import "./colors";
@import "./flexbox";
@import "./margin-padding";
@import "./toast";

html, body, #root {
    color: @pv-grey-16;
    height: 100%;
}
.font-bold {
    font-weight: bold;
}
.full-height {
    height: 100%;
}
.full-min-height {
    min-height: 100%;
}
.full-width {
    width: 100%;
}
h1 {
    font-size: 36px;
    line-height: 44px;
}
h2 {
    font-size: 32px;
    line-height: 40px;
}
h3 {
    font-size: 28px;
    line-height: 36px;
}
h4{
    font-size: 20px;
    line-height: 1.4;
}
h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
}
p, span {
    font-size: 16px;
    line-height: 1.5;
}
.site-container {
    width: 100%;
    height: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin: 0 auto;
    max-width: 1280px;

    @media (min-width: 768px) {
        padding-right: 32px;
        padding-left: 32px;
    }
}

.add-surface-button {
    color: @primary-color !important;
    border: 2px solid @primary-color !important;
    font-weight: bold;
}

.pvFooterMenuProductButton {
    max-height: 56px;
}

// TODO: find a better solution to center loading text
.loader.large {
    .wrapper {
        .text {
            margin-left: -12px;
        }
    }
}
