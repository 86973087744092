.wrapper {
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    font-family: sans-serif;
    width: 100%;
    margin: 10px auto;
}

.step-bar-text-wrapper {
    padding-bottom: 16px;
    margin-left: -10px;
    margin-right: -10px;
}

.progressbar {
    border-top: 4px solid #F5F5F5;
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
}

li {
    list-style-type: none !important;
}

.link {
    position: relative;
    margin-top: 10px;
    width: 100%;
}

.link span {
    font-weight: bold;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-size: 15px;
}

.link:first-child {
    margin-left: -35px;
}

.link:last-child {
    margin-right: -35px;
}

.link::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #F5F5F5;
    position: absolute;
    border-radius: 10px;
    top: -18px;
    left: 50%;
    transform: translatex(-50%);
    border: 2px solid #F5F5F5;
}

/* green finished step dot */
.finish.link::after {
    background: #66CC77;
    border: 2px solid #66CC77;
}

/* green finished progress bar */
.finish.link::before {
    content: "";
    width:100%;
    position: absolute;
    right: -50%;
    top: -14px; /* .link::after -> top-positioning   */
    background: #66CC77;
    border: 2px solid #66CC77;
}
.finish.link:last-child::before {
    content: none;
}

/* current step dot */
.current.link::after {
    width: 16px;
    height: 16px;
    top: -20px;
    border: 2px solid #66CC77;
    background: #66CC77;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05);
}
