.selection-card {
    border-radius: 8px;
}

.selection-card-default{
    padding: 24px;
    background-color: #F5F5F5;
    height: 100%;
    transition: background-color 0.3s;
}

.selection-card-default:hover {
    background-color: #FF184B;
    cursor: pointer;
}
.selection-card-default:hover .text-default {
    color: #FFFFFF;
}

.selection-card-active{
    background-color: #DD1541;
    height: 100%;
    cursor: pointer;
}

.selection-card-disabled{
    padding: 22px;
    border: 2px solid #CCCCCC;
    color: #292929;
    height: 100%;
    cursor: not-allowed;
}

.text-default {
    color: #292929;
    transition: color 0.3s;
}

.text-active {
    color: #FFFFFF;
}
.text-disabled {
    color: #CCCCCC;
}

.disabled-flag {
    background-color: #dd1541;
    color: #FFFFFF;
    padding: 8px 12px;
    border-radius: 0 0 4px 4px;
    position: absolute;
    top: 0;
    right: 24px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    max-width: max-content;
}
