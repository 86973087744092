:root {
  --toastify-color-success: #77CC66;
  --toastify-color-warning: #FFA64D;
  --toastify-color-error: #DD1541;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
}

.Toastify__toast-container {
  width: 600px !important;
}

.Toastify__toast {
  padding: 0 !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

.custom-toast-content-wrapper {
  display: flex;
  flex-direction: row;

  &__left {
    padding: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .toast-headline {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      padding-bottom: 16px;
    }

    .toast-text {
      font-size: 16px;
      line-height: 1.5;
      font-family: "UniversLTStd", "Helvetica Neue", sans-serif !important;
    }

    .toast-link {
      margin-top: 16px;
      cursor: pointer;
      color: white;
      display: inline-flex;
      width: fit-content;
      align-items: center;
      font-size: 16px;
      font-weight: bold;

      .toast-link-icon {
        margin-left: 12px;
        padding-bottom: 4px;
      }
    }
  }

  &__right {
    padding: 28px;
    background: rgba(102, 204, 119, 0.8);

    .toast-icon {
      font-size: 30px;
      position: relative;
      top: calc(50% - 14px);
    }
  }

  .close-icon-wrapper {
    padding: 13px;

    .close-icon {
      font-size: 22px;
      font-weight: bold;
      position: relative;
      top: calc(50% - 11px);
    }
  }
}
