.info-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.info-title {
    margin: 0;
    font-size: 12px;
}

.info-content {
    margin: 0;
    line-height: 24px;
    font-size: 16px;
}

.info-content > .anticon {
    text-align: left;
}
