.form-border-bottom {
    border-bottom: 3px solid #E5E5E5;
}

.form-border-top {
    border-top: 3px solid #E5E5E5;
}

.result-row {
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 3px solid #E5E5E5;
}

.gasFlow-custom-form-attribute .ant-form-item-required::after {
    display: none;
}

.ant-form-item.gasFlow-custom-form-attribute {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
}

.success-border {
    border-color: #66CC77 !important;
}
