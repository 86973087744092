button.ant-btn.pv-yellow-button {
  border-color: @pv-yellow;
  background: @pv-yellow;
  color: @pv-grey-16;
}
.ant-btn.pv-secondary-button {
  border-color:  @primary-color;
  color: @primary-color;
  background-color: transparent !important;
}

a.ant-btn.pv-secondary-button {
  padding-top: 10px !important;
  line-height: 0 !important;
}

.ant-btn[disabled] {
  display: flex;
}

.ant-btn {
  border-radius: unset;
  border-width: 2px;
  font-weight: bold;
  height: auto;
  padding: 12px;
}

.ant-checkbox {
  padding-left: 4px;
  padding-right: 3px;
  transform: scale(1.5) !important;
}

.ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden;
}

.ant-input {
  border-radius: 6px;
}

.ant-input-group:hover {
  .ant-input {
    border: 2px solid #ff4d4f;
    border-right: none;
  }

  .ant-input-group-addon {
    border: 2px solid #ff4d4f;
    border-left: none;
  }
}

.ant-input:focus {
  border-right: 2px solid #ff4d4f !important;
}

.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
  border-right: none;
  border-radius: 6px 0 0 6px;
}
.ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
  border: 2px solid @pv-grey-80;
  border-radius: 0 6px 6px 0;
  border-left: none;
}
.ant-input-group-addon {
  background-color: #fff;

  div.ant-form-item {
    height: 12px;
  }
}
span.ant-input-group-addon {
  text-align: right;
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-right: 2px solid #ff4d4f;
}

.languageMenu {
  padding-top: 3px !important;
}

.ant-modal-title {
  font-weight: 700;
  color: @pv-grey-16;
  font-size: 24px;
  margin: 8px;
}

.ant-radio {
  margin-bottom: 5px !important;
}
.ant-radio-group, .ant-radio-wrapper {
  width: auto !important;
}
.ant-radio-button-checked {
  background-color: @primary-color;
}
.ant-radio-button-wrapper-checked > span:last-of-type {
  color: #fff;
}
.ant-select-allow-clear > .ant-select-clear {
  margin-right: 20px !important;
}
.ant-slider-dot, .ant-slider-disabled .ant-slider-dot, .ant-slider-dot:first-child, .ant-slider-dot:last-child {
  height: 16px;
  width: 2px;
  border-radius: unset;
  border: none;
  background-color: @pv-grey-80;
  top: -5px;
  margin-left: 0;
}
.ant-slider-step {
  background-color: @pv-grey-80;
}
.ant-slider-handle {
  width: 18px;
  height: 18px;
  margin-top: -7px;
  background-color: @primary-color;
  border: 2px solid @primary-color;
}
.ant-slider-mark-text {
  min-width: 40px;
}

.ant-input-number {
  border: solid 2px @pv-grey-80;
  border-radius: 6px;
  padding: 8px 8px 8px 14px;
  gap: 8px;
  height: 40px;
  width: 100%;
}

.ant-input-number-input {
  padding: 0;
  height: auto;
  line-height: 20px;
  font-size: 16px;
}

.ant-input-number-handler.ant-input-number-handler-up {
  border-radius: 0 6px 0 0;
}

.ant-input-number-handler.ant-input-number-handler-down {
  border-radius: 0 0 6px 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
}
