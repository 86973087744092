.ant-collapse-borderless {
    background-color: #fff !important;
}

.ant-collapse-borderless > .ant-collapse-item {
    border: none !important;
}

.collapse-title {
    font-size: 28px;
    line-height: 36px;
}

.ant-collapse-arrow > svg {
    width: 18px !important;
    height: 18px !important;
}
