.summary-card {
    padding: 24px 16px;
    height: 380px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 4px;
    box-shadow: 0 40px 40px -40px #CCCCCC;
}

.summary-card__headline {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
}

.summary-card__text {
    padding: 16px 0;
}

.info-list-wrapper {
    padding: 16px 0;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
}

.key-value-pair {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}

.key-value-pair:last-child {
    margin-bottom: 0;
}

.noise-label {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 2px;
}

.price-label {
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 2px;
}

.mb-20 {
    margin-bottom: 20px;
}

.user-hint-card {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #7a7a7a;
}

.material-icons {
    font-size: 20px;
}

.user-message {
    text-align: center;
}

.summary-card.xs, .summary-card.sm, .summary-card.md {
    height: auto;
}
