.carousel-item-input-label{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    margin: 0;
}

.carousel-item-input{
    background: #FFFFFF;
    border: 2px solid #CCCCCC;
    height: 48px !important;
}

.carousel-item-input .ant-input{
    height: 44px !important;
}

.carousel-item-input .ant-select-selector{
    height: 44px !important;
}
